.Chromecast-Buttons {
	padding: 0  10px 10px 10px;
	background: black;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	gap: 5px;
}

.Chromecast-Icon {
	width: 30px;
}

.Chromecast-Button {
	all: unset;
	padding: 0 4px;
	border-radius: 2px;
	font-size: 14px;
}
