.MediumDetail {
  color: white;
  margin: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
}

.MediumDetail-Header {
  font-weight: bold;
}

.MediumDetail-Body {
}

.MediumDetail-Player {
  max-width: 768px;
  width: 100%;
}
