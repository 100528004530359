.Loader {
  border: 10px solid rgba(255, 255, 255, 0.3);
  border-top: 10px solid rgba(255, 255, 255, 0.6);
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
