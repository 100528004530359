.ContentCardSlider {
}

.ContentCardSlider-Header {
  margin-bottom: 10px;
  color: white;
  font-size: 22px;
  font-weight: bold;
}

.ContentCardSlider-Body {
}

.ContentCardSlider-Group {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 20px;
}

@media screen and (max-width: 1024px) {
  .ContentCardSlider-Group {
    grid-template-columns: repeat(2, 1fr);
  }
}

.ContentCardSlider .carousel-control-next,
.ContentCardSlider .carousel-control-prev {
  opacity: 0.8;
  width: 30px;
  height: 30px;
  margin: 10px;
  top: 22%;
  background: black;
  border-radius: 50%;
}

.ContentCardSlider .carousel-control-next {
  padding: 5px 5px 5px 7px;
}

.ContentCardSlider .carousel-control-prev {
  padding: 5px 7px 5px 5px;
}

.ContentCardSlider .carousel-control-next-icon,
.ContentCardSlider .carousel-control-prev-icon {
}
