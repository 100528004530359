.Cards {
}

.Cards-Loading {
  height: 80vh;
  display: grid;
  place-items: center;
  color: rgba(255, 255, 255, 0.5);
}

.Cards-Error {
  height: 80vh;
  display: grid;
  place-items: center;
  color: rgba(255, 255, 255, 0.5);
}
