.Layout {
  min-height: 100vh;
  padding: 0 40px;
  background-color: var(--color-watch_background_color, var(--color-primary));

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: linear-gradient(
      var(--color-watch_background_color),
      rgba(0, 0, 0, 0.5),
      var(--color-watch_background_color)
    ),
    var(--url-background);
}

.Layout-Header {
}

.Layout-Main {
}
