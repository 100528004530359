.SearchBar {
  padding-bottom: 30px;
}

.SearchBar-Form {
  display: grid;
  place-items: center;
}

.SearchBar-Input {
  width: 100%;
  max-width: 700px;
  height: 50px;
  padding: 10px 10px 10px 50px;
  border: none;
  border-radius: 30px;
  outline: none;
  color: white;
  font-size: 15px;

  background-image: url(https://s3.amazonaws.com/alliance-js-assets.fanreachdata.io/icons/search.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center left 15px;
  background-size: 25px;
  background-color: rgba(255, 255, 255, 0.1);
}

.SearchBar-Input::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  background: url(https://pro.fontawesome.com/releases/v5.10.0/svgs/solid/times-circle.svg)
    no-repeat 50% 50%;
  background-size: contain;
  filter: invert(1);
}

.SearchBar-Button {
  all: unset;
  color: rgba(255, 255, 255, 0.5);
  font-weight: bold;
}
