.ConfigurationProvider {
}

.ConfigurationProvider-Loading {
  height: 80vh;
  display: grid;
  place-items: center;
  background: rgb(25, 25, 25);
  color: rgba(255, 255, 255, 0.5);
}

.ConfigurationProvider-Error {
  height: 80vh;
  display: grid;
  place-items: center;
  background: rgb(25, 25, 25);
  color: rgba(255, 255, 255, 0.5);
}
