.ContentCardGrid {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  justify-content: center;
  margin: 0 1rem;
}

.ContentCardGrid .ContentCard {
}

.ContentCardGrid .Card_AppearanceFlat .Card-Body {
  margin: 10px 10px 0 10px;
  grid-gap: 10px;
}

.ContentCardGrid .ContentCard-Text {
  font-size: 0.7rem;
}

.ContentCardGrid .ContentCard-Datetime {
  font-size: 0.575rem;
}

.ContentCardGrid .ContentCard .Button {
  padding: 0.2rem 0.5rem 0.2rem 0.3rem;
}

.ContentCardGrid .ContentCard .Button-Image {
  width: 0.8rem;
  height: 0.8rem;
  margin-right: 0.3rem;
}

.ContentCardGrid .ContentCard .Button-Text {
  font-size: 0.5rem;
}
