/* Normalize */
@import-normalize;

/* CSS Variables */
:root {
}

/* Fonts */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:ital,wght@0,300;0,700;1,300&display=swap");

:root {
  font-family: "Open Sans", sans-serif;
}

/* Defaults */

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
}

a {
  text-decoration: none;
  color: inherit;
}
