.LeagueNav {
  margin-bottom: 40px;
}

.LeagueNav-Header {
  margin-bottom: 10px;
  color: white;
  font-size: 22px;
  font-weight: bold;
}

.LeagueNav-Links {
  display: flex;
  gap: 20px;
}

.LeagueNav-Link {
  flex: 1;
  max-width: 220px;
  height: 90px;
  border-radius: 4px;
  overflow: hidden;
}

.LeagueNav-Image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
