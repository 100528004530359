.Player {
}

.Player-Player {
  background-color: black;
}

.Switch {
  padding: 15px 22px 22px 22px;
  background-color: black;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.Switch-Label {
  margin: 0 8px 0 0;
  font-size: 15px;
}

.Switch-Image {
  width: 17px;
  filter: invert(1);
  opacity: 0.9;
  margin-right: 8px;
}

.Switch-Button {
  all: unset;
  padding: 2px 16px;
  font-size: 14px;
  border: 2px solid black;
  border-radius: 5px;
  font-weight: bold;
  background-color: #8951ff;
  color: white;
}
