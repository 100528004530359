.ContentCard {
  display: block;
  width: 100%;
  min-width: 0;
}

.ContentCard-Image {
  width: 100%;
  aspect-ratio: 16 / 9;
  border-radius: 6px;
}

.ContentCard-Body {
  padding: 0.5rem 0 0.5rem 0;
}

.ContentCard-Body > * {
  margin: 0;
  padding: 0;
  line-height: normal;
}

.ContentCard-Time {
  margin-bottom: 5px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
}

.ContentCard-Title {
  margin-bottom: 5px;

  color: white;
  font-size: 16px;
  font-weight: bold;
  text-overflow: ellipsis;
  line-height: 1.5;
}

.ContentCard-Date {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
}
