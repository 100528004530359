.WacNav {
  display: flex;
  gap: 24px;
}

.WacNav-Signin {
  all: unset;
  background: #5633a1;
  border-radius: 4px;
  padding: 4px 16px;
  color: white;
  font-weight: bold;
  font-size: 16px;
}
