.Settings {
  --bs-link-hover-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Settings-Article {
  margin: 30px;
}

.Settings-Heading {
  color: white;
  font-weight: bold;
  text-align: center;
}

.Settings-Paragraph {
  max-width: 750px;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
}

.Settings-Links {
  max-width: 750px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: bold;
  text-align: center;
}

.Settings-Link {
  display: block;
}

.Settings-Language {
  margin-left: 1rem;
  padding: 2px 16px;
  border: 2px solid black;
  border-radius: 5px;
  font-weight: bold;
  background-color: #8951ff;
  color: white;
}

.Settings-Setting {
  color: white;
  font-weight: bold;
}
